import { IProfilesdStatusReq } from "./strings";

export const ProfileCreationTypes = {
  1: { key: "create_new_profile", name: "יצירת פרופיל חדש" },
  2: { key: "update_profile", name: "עדכון פרופיל" },
  3: { key: "new_card", name: "כרטיס חדש" },
} as const;

export type ProfileReqResponse = {
  type: keyof typeof ProfileCreationTypes;
  status: IProfilesdStatusReq;
  cardNumber: string | null;
  profileName: string;
  _id: string;
  request_type: number;
  docs: IProfileDocReq[];
  userId: string;
  createdAt: string;
  updatedAt: string;
  cardRequestProfiles: ProfileReqResponse[];
  cardRequest: string;
  __v: number;
};

export interface IProfileDocReq {
  originalFileName?: string | undefined;
  fileExtension?: string | undefined;
  ortDocName: string;
  updatedAt: string;
  comments: string;
  status: boolean;
  _id: string;
  url: string;
  doc_type: string;
}

export interface IUPDATE_FILE_REQ_PROFILE {
  status: boolean;
  comments?: string;
}

export interface IProfilesReqCountByStatus {
  new_request: number;
  treatment_request: number;
  returned_request: number;
  denied_request: number;
  approved_request: number;
}

export interface IUpdateStatusProfileReqBody {
  status: IProfilesdStatusReq;
}
