export enum ProfilesStatusReqEnum {
  new_request = "new-request",
  treatment_request = "treatment-request",
  returned_request = "returned-request",
  denied_request = "denied-request",
  approved_request = "approved-request",
}

export type IProfilesdStatusReq =
  | ProfilesStatusReqEnum.approved_request
  | ProfilesStatusReqEnum.denied_request
  | ProfilesStatusReqEnum.new_request
  | ProfilesStatusReqEnum.returned_request
  | ProfilesStatusReqEnum.treatment_request;

export type profilesFilterByStatus = "all" | IProfilesdStatusReq;

export type filterProfilesByStatusReqSortBy =
  | "type"
  | "cardNumber"
  | "status"
  | "createdAt";

export enum orderBy {
  ASC = 1,
  DESC = -1,
}
