import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";

import { store } from './app/store'
import { Provider } from 'react-redux'
import AppRoutes from "./appRotes";
import "./assets/styles/css/style.css";


ReactDOM.render(
  // <React.StrictMode>
    <ConfigProvider direction="rtl">
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </ConfigProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
