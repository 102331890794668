import { useEffect, useState } from "react"

const useDate = () => {
    
    const [date, setDate] = useState<string>('')
    const [clock, setClock] = useState<string>('')
  
    useEffect(()=>{
        let refresh = 1000; // Refresh rate in milli seconds
        let intervalId =  setInterval(display_ct, refresh)

        return () => {
            // 👇️ clear timeout when component unmounts
            console.log("interval is stop")
            clearInterval(intervalId)
          };
        
    },[])

    function display_ct() {
        let x = new Date()
        // let date = x.getMonth() + 1 + "/" + x.getDate() + "/" + x.getFullYear(); 
        let date = x.getDate() + "/" + (x.getMonth() + 1)  + "/" + x.getFullYear(); 
        setDate(date);
        let clock = addZero(x.getHours()) + ":" + addZero(x.getMinutes());
        setClock(clock);
    }

    function addZero (time:any) {
        if(time < 10 && time >= 0){
            return `0${time}`
        }
        return time
    }
  
  return {date, clock}
}

export default useDate