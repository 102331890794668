import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IProfilesReqCountByStatus,
  ProfileReqResponse,
} from "../../common/consts/model";
import {
  filterProfilesByStatusReqSortBy,
  orderBy,
  profilesFilterByStatus,
} from "../../common/consts/strings";

// Define a type for the slice state
interface ProfilesState {
  profilesListReq: {
    data: ProfileReqResponse[] | null;
    getProfileByFilter: profilesFilterByStatus;
    sortBy: filterProfilesByStatusReqSortBy | null;
    order: orderBy.ASC | orderBy.DESC;
    reqCountsByStatus: IProfilesReqCountByStatus;
  };
}

// Define the initial state using that type
const initialState: ProfilesState = {
  profilesListReq: {
    data: null,
    getProfileByFilter: "all",
    sortBy: null,
    order: orderBy.DESC,
    reqCountsByStatus: {
      approved_request: 0,
      denied_request: 0,
      new_request: 0,
      returned_request: 0,
      treatment_request: 0,
    },
  },
};

export const profilesSlice = createSlice({
  name: "profiles",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    chengeAllProfilesreq: (
      state,
      action: PayloadAction<ProfileReqResponse[]>
    ) => {
      state.profilesListReq = {
        ...state.profilesListReq,
        data: action.payload,
      };
    },
    chengeGetProfileByFilter: (
      state,
      action: PayloadAction<profilesFilterByStatus>
    ) => {
      state.profilesListReq = {
        ...state.profilesListReq,
        getProfileByFilter: action.payload,
      };
    },
    chengeyGetProfilesSortBy: (
      state,
      action: PayloadAction<filterProfilesByStatusReqSortBy | null>
    ) => {
      state.profilesListReq = {
        ...state.profilesListReq,
        sortBy: action.payload,
      };
    },
    chengeyGetProfilesOrder: (state) => {
      state.profilesListReq = {
        ...state.profilesListReq,
        order: state.profilesListReq.order * -1,
      };
    },
    updateSingelReq: (state, action: PayloadAction<ProfileReqResponse>) => {
      let index = -1;
      let innerIndex = -1;
      state.profilesListReq.data?.forEach((p, i) => {
        if (p._id === action.payload._id) {
          index = i;
        }
        if (p.cardRequestProfiles?.length) {
          p.cardRequestProfiles.forEach((innerP, j) => {
            if (innerP._id === action.payload._id) {
              index = i;
              innerIndex = j;
            }
          });
        }
      });
      if (index === -1) return;
      console.log({ index, innerIndex });

      let cloneProfiles = [...(state.profilesListReq.data ?? [])];
      if (innerIndex !== -1) {
        cloneProfiles[index].cardRequestProfiles[innerIndex] = action.payload;
        state.profilesListReq = {
          ...state.profilesListReq,
          data: cloneProfiles || null,
        };
        return;
      }
      cloneProfiles[index] = action.payload;
      state.profilesListReq = {
        ...state.profilesListReq,
        data: cloneProfiles || null,
      };
    },
    chengeReqCountsByStatus: (
      state,
      action: PayloadAction<IProfilesReqCountByStatus>
    ) => {
      state.profilesListReq = {
        ...state.profilesListReq,
        reqCountsByStatus: action.payload,
      };
    },
  },
});

export const {
  chengeAllProfilesreq,
  chengeGetProfileByFilter,
  chengeyGetProfilesSortBy,
  chengeyGetProfilesOrder,
  updateSingelReq,
  chengeReqCountsByStatus,
} = profilesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.profiles.value

export default profilesSlice.reducer;
