import React, { FC, useState } from 'react';

interface BoxProps {
    imageUrl: string,
    title: number,
    description: string,
    color: string;
}

const DynamicBox: FC<BoxProps> = ({ imageUrl, title, description, color }) => {
    return (
        <div className='dynamic-box' title={"Click again to cancel"}>
            <div className={`stripe ${color}`}></div>
            <img className="logo" src={imageUrl} alt="" />
            <div className="content">
                <h1>{title}</h1>
                <div className="description">{description}</div>
            </div>
        </div>
    )
}

export default DynamicBox;