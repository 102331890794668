import React, { FC } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useDate from "../../common/hooks/useDate";
import useNavigatorOnLine from "../../common/hooks/useOnline";
const menuList = [
  {
    title: "מסך ראשי",
    url: "https://home"
  }
];
const mainMenu = menuList.map((item, key: number) => {
  return (
    <li className="menu-bar-item" key={key} >
      <Link to={item.url}>
        {item.title}
      </Link>
    </li>
  );
});

interface Props {}

const Footer: FC<Props> = () => {

    const {date, clock} = useDate()
    const {status:isOnline, ip} = useNavigatorOnLine();
    

  return (
    <div className="Footer">
        <div className="footer-container">
            <div className="footer-clock-container">
                <img src={`${process.env.PUBLIC_URL}/images/icons-clock.svg`} alt="" />
                <div> 
                    <span>{date}</span> 
                    <span className="time">{clock}</span>
                </div>
            </div>

            <div className="footer-ip-container">
                <img className={"wifi-icon " +  (!isOnline ? "Offline" : "")}src={`${process.env.PUBLIC_URL}/images/icons-online.svg`} alt="" />
                <div className="online">
                    <span>{isOnline ? "Online" : "Offline"}</span> 
                    <span className="space">{`IP`}</span>
                    <span className="space">{ip}</span>
                    <span className="space">{`v1.0`}</span>
                    
                </div>
            </div>
        </div>
        
        
     
      {/* <div className="bottom-section">
          <Link className="link-back" to="http://"><img src={`${process.env.PUBLIC_URL}/images/icons-arrow-right.svg`} alt=""/>חזרה</Link>
          <Link className="link-exit" to="http://">יציאה<img src={`${process.env.PUBLIC_URL}/images/icons-exit.svg`} alt="" /></Link>
      </div> */}
    </div>
  );
};

export default Footer;
