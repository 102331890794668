import { FC, useState } from "react";
import ProfileDoc from "./ProfileDoc";
import { IProfileDocReq, ProfileReqResponse } from "../../common/consts/model";
import { UPDATE_STATUS_PROFILES_REQ } from "../../common/services/api";
import {
  IProfilesdStatusReq,
  ProfilesStatusReqEnum,
} from "../../common/consts/strings";
import { updateSingelReq } from "../../features/profiles/profilesSlice";
import { useAppDispatch } from "../../app/hooks";
import { Spin } from "antd";

const hendleIsAllDocsApproved = (
  docs: IProfileDocReq[]
): { allDocsIsUpdated: boolean; allDocsIsApproved: boolean } => {
  let allDocsIsUpdated: boolean;
  let allDocsIsApproved: boolean;
  let docLength = docs?.length || 0;
  let updatedDocs = 0;
  let approvedDocs = 0;

  docs.map((doc: IProfileDocReq) => {
    if (doc.status) {
      approvedDocs++;
      updatedDocs++;
    } else {
      if (doc.comments) {
        updatedDocs++;
      }
    }
  });
  // all is update and not approvedDocs
  allDocsIsUpdated = updatedDocs === docLength && docLength > 0;
  // even one update
  // allDocsIsUpdated = (updatedDocs > 0 );
  allDocsIsApproved = approvedDocs === docLength && docLength > 0;
  return { allDocsIsUpdated, allDocsIsApproved };
};

interface IProfileDocs {
  profileReq: ProfileReqResponse;
}

const ProfileRequest: FC<IProfileDocs> = ({ profileReq }) => {
  const { _id: profileReqId, docs, status: profileStatus } = profileReq;
  const allDocsIsApproved = hendleIsAllDocsApproved(docs).allDocsIsApproved;
  const allDocsIsUpdated = hendleIsAllDocsApproved(docs).allDocsIsUpdated;
  let dispatch = useAppDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const sendToClient = async () => {
    setIsUpdating(true);
    if (allDocsIsUpdated && !allDocsIsApproved) {
      if (profileStatus !== ProfilesStatusReqEnum.denied_request) {
        await updateProfileStatusApi(ProfilesStatusReqEnum.denied_request);
      }
      // ToDo
      // ...... send to
    } else {
      await updateProfileStatusApi(ProfilesStatusReqEnum.approved_request);
    }
    setIsUpdating(false);
  };

  const updateProfileStatusApi = async (status: IProfilesdStatusReq) => {
    const profile = await UPDATE_STATUS_PROFILES_REQ(profileReqId, { status });
    dispatch(updateSingelReq(profile));
    return profile;
  };

  return (
    <>
      {docs.length ? (
        docs.map((doc: IProfileDocReq) => (
          <ProfileDoc
            key={doc._id}
            name={doc.ortDocName || doc.originalFileName || ""}
            image={doc.url}
            approved={doc.status}
            comments={doc.comments}
            docID={doc._id}
            profileReqId={profileReqId}
          />
        ))
      ) : (
        <div style={{ textAlign: "center" }}>לא קיימים מסמכים</div>
      )}

      <button
        data-is-disabled={!allDocsIsUpdated}
        disabled={!allDocsIsUpdated || isUpdating}
        className="send"
        onClick={sendToClient}
      >
        {isUpdating && <Spin />}
        {allDocsIsApproved ? "העברה לביצוע" : "שליחה ללקוח"}
      </button>
    </>
  );
};

export default ProfileRequest;
