import * as API_ROUTES from "../../common/consts/api_routes";
import {
  IProfilesReqCountByStatus,
  IUpdateStatusProfileReqBody,
  IUPDATE_FILE_REQ_PROFILE,
  ProfileReqResponse,
} from "../consts/model";
import {
  filterProfilesByStatusReqSortBy,
  profilesFilterByStatus,
  orderBy,
} from "../consts/strings";

const FETCH_API = async <T = any>(
  _url: string,
  _method: "POST" | "PUT" | "PATCH" | "DELETE" | "GET",
  _body?: any,
  isToken = false
): Promise<T> => {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  if (isToken)
    headers.append("Authorization", `Bearer ${localStorage["MY_ZONE_TOKEN"]}`);
  try {
    const resp = await fetch(_url, {
      method: _method,
      body: JSON.stringify(_body),
      headers,
    });
    return await resp.json();
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

const getProfiles = async ({
  filter,
  sortBy,
  order = orderBy.ASC,
  offset = 0,
  limit = 10,
}: {
  filter: profilesFilterByStatus;
  sortBy?: filterProfilesByStatusReqSortBy | null;
  order: orderBy.ASC | orderBy.DESC;
  offset?: number;
  limit?: number;
}): Promise<{ profiles: ProfileReqResponse[]; count: number }> => {
  let uri = `${
    sortBy ? "/" + sortBy : ""
  }?order=${order}&offset=${offset}&limit=${limit}`;
  return FETCH_API(`${API_ROUTES.GET_ALL_PROFILES_REQ}${filter}${uri}`, "GET");
};

const UPDATE_FILE_REQ_PROFILE_STATUS = async (
  reqProfileId: string,
  fileId: string,
  payload: IUPDATE_FILE_REQ_PROFILE
): Promise<ProfileReqResponse> => {
  return FETCH_API(
    `${API_ROUTES.CHENGE_FILE_REQ_PROFILE_STATUS}${reqProfileId}/${fileId}`,
    "POST",
    payload
  );
};

const FETCH_PROFILES_REQ_COUNT_BY_STATUS =
  async (): Promise<IProfilesReqCountByStatus> => {
    return FETCH_API(API_ROUTES.GET_PROFILES_REQ_COUNT_BY_STATUS, "GET");
  };

const UPDATE_STATUS_PROFILES_REQ = async (
  profileId: string,
  body: IUpdateStatusProfileReqBody
) => {
  return FETCH_API(
    `${API_ROUTES.UPDATE_PROFILES_REQ_STATUS}${profileId}`,
    "POST",
    body
  );
};

function getBase64Image(imgId: string) {
  return FETCH_API(`${API_ROUTES.GET_IMAGE_BASE64}/${imgId}`, "GET");
}

const sendCardReqToOrt = async (body: { reqId: string }) => {
  return FETCH_API(API_ROUTES.CARD_PRINT_REQ, "POST", body);
};

export {
  getProfiles,
  getBase64Image,
  UPDATE_FILE_REQ_PROFILE_STATUS,
  FETCH_PROFILES_REQ_COUNT_BY_STATUS,
  UPDATE_STATUS_PROFILES_REQ,
  sendCardReqToOrt,
};
