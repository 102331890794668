import React, { useEffect } from "react";
import ProfileDoc from "./ProfileDoc";
import { IProfileDocReq, ProfileReqResponse } from "../../common/consts/model";
import { sendCardReqToOrt } from "../../common/services/api";
import "./CardRequest.css";

import { Spin } from "antd";

interface IProfileDocs {
  profileReq: ProfileReqResponse;
  onChange: () => void;
}

const CardRequest: React.FC<IProfileDocs> = ({ profileReq, onChange }) => {
  const { _id: profileReqId, docs, cardRequestProfiles } = profileReq;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(true);
  useEffect(() => {
    if (isSuccess) {
      const timeout = setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      const timeout = setTimeout(() => {
        setIsError(false);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isError]);
  const sendToPrint = () => {
    setIsLoading(true);
    sendCardReqToOrt({ reqId: profileReqId })
      .then((res) => {
        setIsSuccess(true);
      })
      .catch((err) => {
        setIsError(true);
      })
      .finally(() => {
        onChange();
        setIsLoading(false);
      });
  };

  const flags = {
    isProfilesApproved: cardRequestProfiles.every((req) =>
      req.docs.every((doc) => doc.status)
    ),
    isCardApproved: profileReq.docs.every((doc) => doc.status),
  };

  return (
    <>
      {docs.length ? (
        docs.map((doc: IProfileDocReq) => (
          <ProfileDoc
            key={doc._id}
            name={doc.ortDocName || doc.originalFileName || ""}
            image={doc.url}
            approved={doc.status}
            comments={doc.comments}
            docID={doc._id}
            profileReqId={profileReqId}
          />
        ))
      ) : (
        <div style={{ textAlign: "center" }}>אין מסמכים</div>
      )}
      {cardRequestProfiles?.length
        ? cardRequestProfiles.map((req) => (
            <>
              <div className="inner-profile-header">
                <div className="inner-profile-header-text">{`פרופיל ${req.profileName}`}</div>
              </div>
              {req.docs?.length ? (
                req.docs.map((doc: IProfileDocReq) => (
                  <ProfileDoc
                    key={doc._id}
                    name={doc.ortDocName || doc.originalFileName || ""}
                    image={doc.url}
                    approved={doc.status}
                    comments={doc.comments}
                    docID={doc._id}
                    profileReqId={req._id}
                  />
                ))
              ) : (
                <div style={{ textAlign: "center" }}>No Dock</div>
              )}
            </>
          ))
        : null}
      <button
        data-is-disabled={!flags.isCardApproved || !flags.isProfilesApproved}
        data-is-error={isError}
        data-is-success={isSuccess}
        disabled={!flags.isCardApproved || !flags.isProfilesApproved}
        color="primary"
        className="send"
        onClick={sendToPrint}
      >
        {isLoading && <Spin />}
        שליחת כרטיס
      </button>
    </>
  );
};

export default CardRequest;
