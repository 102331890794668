import React, { useEffect, useState } from "react";
// import "../../assets/styles/css/style.css";
// import NavBar from "../../components/Navbar/Navbar";
import BoxesContainer from "../../components/BoxesContainer";
import CollapseList from "../../components/CollapseList/CollapseList";
import { Dropdown, Space, Radio, Button, Select } from "antd";
import { getProfiles } from "../../common/services/api";
import { profilesFilterByStatus } from "../../common/consts/strings";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  chengeAllProfilesreq,
  chengeGetProfileByFilter,
  chengeyGetProfilesOrder,
  chengeyGetProfilesSortBy,
} from "../../features/profiles/profilesSlice";
// import Footer from "../../components/Footer/Footer";

/**
 * <Space direction="vertical">
        <Radio value="type">סוג בקשה</Radio>
        <Radio value="cardNumber">מספר כרטיס</Radio>
        {/* <Radio value="profile">סוג פרופיל</Radio> 
 *       <Radio value="status">סטטוס בקשה</Radio>
   *     <Radio value="createdAt">תאריך פנייה</Radio>
      </Space>
 */

const sortOptions = [
  { label: "תאריך פנייה", value: "createdAt" },
  { label: "סטטוס בקשה", value: "status" },
  { label: "מספר כרטיס", value: "cardNumber" },
  { label: "סוג בקשה", value: "type" },
];

function ProfilesReq() {
  const dispatch = useAppDispatch();
  const [sortChecked, setSortChecked] = useState<string>(sortOptions[0].value);
  const { getProfileByFilter, sortBy, order } = useAppSelector(
    (state) => state.profiles.profilesListReq
  );
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getAllProfilesReq();
  }, [getProfileByFilter, sortBy, order, limit, offset]);

  const getAllProfilesReq = async () => {
    setIsLoading(true);
    let { profiles, count } = await getProfiles({
      filter: getProfileByFilter,
      sortBy,
      order,
      offset,
      limit,
    });
    setIsLoading(false);
    setTotal(count);
    dispatch(chengeAllProfilesreq(profiles));
    console.log(profiles);
  };

  const onPrevPage = () => {
    if (offset === 0) return;
    if (offset - limit < 0) return setOffset(0);
    setOffset(offset - limit);
  };
  const onNextPage = () => {
    setOffset(offset + limit);
  };

  /** Filter the profile requests list by type of selected tab. */
  const onTabChanged = (ProfileRequestStatus: profilesFilterByStatus) => {
    console.log(ProfileRequestStatus);
    dispatch(chengeGetProfileByFilter(ProfileRequestStatus));
  };

  const onSortChanged = (e: any) => {
    console.log("sort checked: ", e.target.value);
    dispatch(chengeyGetProfilesSortBy(e.target.value));
    setSortChecked(e.target.value);
  };

  const sortMenu = (
    <Radio.Group onChange={onSortChanged} value={sortChecked}>
      <Space direction="vertical">
        {sortOptions.map((option) => (
          <Radio key={option.value} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );

  return (
    <div className="profiles-req-container">
      <BoxesContainer onTabChanged={onTabChanged} />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Select
          defaultValue={10}
          style={{ width: 120, margin: "0 10px" }}
          onChange={(value) => setLimit(value)}
        >
          <Select.Option value={10}> 10 לעמוד</Select.Option>
          <Select.Option value={20}> 20 לעמוד</Select.Option>
          <Select.Option value={50}> 50 לעמוד</Select.Option>
          <Select.Option value={100}> 100 לעמוד</Select.Option>
        </Select>

        <Button disabled={offset === 0 || isLoading} onClick={onPrevPage}>
          הקודם
        </Button>
        <span style={{ margin: "0 10px" }}>
          {offset + 1} - {offset + limit} מתוך {total}
        </span>
        <Button
          disabled={offset + limit >= total || isLoading}
          onClick={onNextPage}
        >
          הבא
        </Button>
        <Dropdown
          overlay={sortMenu}
          trigger={["hover"]}
          overlayClassName="sort-menu"
          placement="bottomLeft"
          overlayStyle={{ minWidth: "none" }}
        >
          <a className="ant-dropdown-link" href="#">
            ({order === 1 ? "עולה" : "יורד"})
            <img
              style={{ margin: "6px" }}
              src={`${process.env.PUBLIC_URL}/images/icons-flip.svg`}
              alt=""
              onClick={() => dispatch(chengeyGetProfilesOrder())}
            />
            מיין לפי{" "}
            <img
              src={`${process.env.PUBLIC_URL}/images/icons-small-arrow-down.svg`}
              alt=""
            />
          </a>
        </Dropdown>
      </div>
      <CollapseList onRefetch={getAllProfilesReq}/>
    </div>
  );
}

export default ProfilesReq;
