import { FC, useState } from "react";
import { Button, Radio, Dropdown, Space, Spin } from "antd";
import { openBase64 } from "../../common/services/openBase64";
import {
  UPDATE_FILE_REQ_PROFILE_STATUS,
  getBase64Image,
} from "../../common/services/api";
import { updateSingelReq } from "../../features/profiles/profilesSlice";
import { useAppDispatch } from "../../app/hooks";
import Text from "antd/lib/typography/Text";

interface ProfileDocProps {
  name: string;
  image: string;
  approved: boolean;
  comments?: string;
  docID: string;
  profileReqId: string;
}
const denyOptions = ["צילום לא ברור", "חסר", "פג תוקף", "תעודה לא שייכת"];

const ProfileDoc: FC<ProfileDocProps> = ({
  profileReqId,
  docID,
  name,
  image,
  approved,
  comments,
}) => {
  const [isApproved, setIsApproved] = useState(approved);
  const [reasonChecked, setReasonChecked] = useState(comments);
  const [isUpdating, setIsUpdating] = useState(false);
  let dispatch = useAppDispatch();

  const handleApprovedChange = async (e: any) => {
    console.log("IsApproved? :", e.target.value);
    setIsApproved(e.target.value);
    if (e.target.value) {
      setReasonChecked("");
      await updateFile(e.target.value, "");
    }
  };

  const onReasonChange = async (e: any) => {
    console.log("radio checked: ", e.target);
    setReasonChecked(e.target.value);
    await updateFile(false, e.target.value);
  };

  const updateFile = async (status: boolean, comments: string) => {
    setIsUpdating(true);
    let data = await UPDATE_FILE_REQ_PROFILE_STATUS(profileReqId, docID, {
      status,
      comments,
    });
    dispatch(updateSingelReq(data));
    setIsUpdating(false);
    console.log(data);
  };
  const DenyMenu = () => (
    <Radio.Group onChange={onReasonChange} value={reasonChecked}>
      <Space direction="vertical">
        {denyOptions.map((denyOption) => (
          <Radio value={denyOption}>{denyOption}</Radio>
        ))}
      </Space>
    </Radio.Group>
  );

  return (
    <div className="profile-item">
      <div
        className="show-image"
        onClick={async () => {
          if (
            typeof image === "string" &&
            image.trim().startsWith("data:image")
          ) {
            openBase64(image);
            return;
          }

          const base64 = await getBase64Image(image);
          openBase64(base64);
        }}
      >
        <Button type="link">{name}</Button>
        <img src={`${process.env.PUBLIC_URL}/images/icons-eye.svg`} alt="" />
      </div>

      <Radio.Group
        className="buttons"
        value={isApproved}
        onChange={handleApprovedChange}
      >
        <Dropdown overlay={DenyMenu} trigger={["click"]}>
          <Radio.Button
            className={reasonChecked?.length && !approved ? "not-approved" : ""}
            value={false}
            disabled={isUpdating}
          >
            {isUpdating && !isApproved && <Spin size="small" />}{" "}
            <Text>לא מאושר</Text>{" "}
            {reasonChecked?.length && !approved ? `- ${reasonChecked}` : ""}
            <img
              src={`${process.env.PUBLIC_URL}/images/icons-small-arrow-down.svg`}
              alt="open deny menu arrow"
            />
          </Radio.Button>
        </Dropdown>
        <Radio.Button
          className={approved ? "approved" : ""}
          value={true}
          disabled={isUpdating}
        >
          {isUpdating && isApproved && <Spin size="small" />} <Text>מאושר</Text>
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default ProfileDoc;
