import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

// Define a type for the slice state
interface authState {
  logIn :{
    data : any | null
  }
}

// Define the initial state using that type
const initialState: authState = {
  logIn :{
    data : null
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    chengeLogInData: (state, action: PayloadAction<any>) => {
      state.logIn =  {...state.logIn, data : action.payload} 
    },
  },
})

export const { 
  chengeLogInData
} = authSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.profiles.value

export default authSlice.reducer