import React, { FC } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { chengeLogInData } from "../../features/auth/authSlice";


interface Props {}

const LogIn: FC<Props> = () => {

  const dispatch = useAppDispatch()
  const navigate = useNavigate();


  const onFormSub = (e:any) =>{
    e.preventDefault();
    dispatch(chengeLogInData(true))
    navigate("/profiles")
  }
  

  return (
    <div className="logIn" style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", height:'100vh'}}>
        <h1>Log In Page to do</h1>
        <h2>
          לחץ על התחבר
        </h2>
        <form onSubmit={onFormSub}  style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
          <input type="text"/>
          <button className="ant-btn-link">התחבר</button>
        </form>
    </div>
  );
};

export default LogIn;
