import React, { useEffect, useState } from "react";
import axios from "axios";

const getOnLineStatus = () => {
  return typeof navigator !== "undefined" &&
    typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;
};

const useNavigatorOnLine = () => {
  const [status, setStatus] = useState(getOnLineStatus());
  const [ip, setIP] = useState("");
  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  useEffect(() => {
    // getData()
  }, []);

  return { status, ip };
};

export default useNavigatorOnLine;
