import React, { FC } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { chengeLogInData } from "../../features/auth/authSlice";
const menuList = [
  {
    title: "מסך ראשי",
    url: "profiles"
  },
  {
    title: "דוגמה",
    url: "log-in"
  }
];

const mainMenu = menuList.map((item, key: number) => {
  return (
    <li className="menu-bar-item" key={key} >
      <NavLink className={"navLink " +((navData:any)=>navData.isActive ? "active" : "")} to={item.url}>
        {item.title}
      </NavLink>
    </li>
  );
});

interface Props {}

const NavBar: FC<Props> = () => {

  const navigate = useNavigate();
  const logInData = useAppSelector((state) => state.auth.logIn.data);
  const dispatch = useAppDispatch()


  const logOut = () => {
    dispatch(chengeLogInData(false))
  }

  return (
    <div className="Navbar">
      <div className="menu-bar">
        <div className="menu-and-logo-container">
          <img className="logo" src={`${process.env.PUBLIC_URL}/images/grid-logo.svg`} alt="" />
          { logInData && <ul className="menu">{mainMenu}</ul>}
        </div>
        
        { 
          !logInData && (
            <Link to="/log-in">
              <div className='log-in-container'>
                {/* <img className="logInIcon" src={`${process.env.PUBLIC_URL}/images/icon-log-in-test.png`} alt="" />   */}
                <img className="logInIcon" src={`${process.env.PUBLIC_URL}/images/icons-log-in.png`} alt="" />  
                התחבר  
              </div>
            </Link>
          )
        }
      </div>
     
     {
       logInData && (
        <div className="bottom-section">
          <div className="link-back" onClick={()=> navigate(-1)}>
            <img src={`${process.env.PUBLIC_URL}/images/icons-arrow-right.svg`} alt=""/>
            <span style={{paddingRight:"3px"}}>חזרה</span> 
          </div>
          <Link className="link-exit" to='/' onClick={logOut}>יציאה
            <img src={`${process.env.PUBLIC_URL}/images/icons-exit.svg`} alt="" />
          </Link>
        </div>
       )
     }
    </div>
  );
};

export default NavBar;
