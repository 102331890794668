import React from "react";
import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import NavBar from "../Navbar/Navbar";
import { chengeLogInData } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";

interface Props {}

const AppMain: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(chengeLogInData(true));
    navigate("/profiles");
  }, []);

  return (
    <div className="App">
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default AppMain;
