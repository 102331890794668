import React, { FC, useMemo, useState } from "react";
import { Collapse, Button } from "antd";
import ProfileRequest from "./ProfileRequest";
import { useAppSelector } from "../../app/hooks";
import {
  ProfileCreationTypes,
  ProfileReqResponse,
} from "../../common/consts/model";
import { getDateFormat } from "../../common/helper-functions";
import CardRequest from "./CardRequest";
const { Panel } = Collapse;

type ProfileDoc = {
  image: string;
  approved: boolean;
  reasonNot?: string;
};

interface Request {
  cardNumber: number;
  profile: string;
  status: string;
  date: string;
  type: string;
  identityCard: ProfileDoc;
  studentPermit: ProfileDoc;
  studentCard: ProfileDoc;
}
const statusObj = {
  "new-request": { tagColor: "orange", hebStatus: "לא מאושר" },
  "treatment-request": { tagColor: "light-blue", hebStatus: "בטיפול" },
  "returned-request": { tagColor: "dark-blue", hebStatus: "ממתין לאישור" },
  "approved-request": { tagColor: "light-green", hebStatus: "אושר" },
  "denied-request": { tagColor: "red", hebStatus: "לא מאושר" },
};

// cardNumber: string, profile: string, status: string, date: string, type: string
const RequestHeader = ({ details }: { details: ProfileReqResponse }) => {
  const labels = useMemo(() => {
    const profileNum = details.cardRequestProfiles.length ?? 0;
    const profileName = (() => {
      if (profileNum === 0) return details.profileName;
      if (profileNum === 1) return "פרופיל 1";
      if (profileNum === 2) return "2 פרופילים";
    })();
    return {
      tagColor: statusObj[details.status]?.tagColor,
      hebStatus: statusObj[details.status]?.hebStatus,
      type:
        typeof details.type === "number" &&
        details.type in ProfileCreationTypes &&
        ProfileCreationTypes[details.type].name,
      cardNumber: details.cardNumber ? details.cardNumber : "טרם הונפק",
      profileName,
    };
  }, [details]);

  return (
    <div className="header">
      <div className={`tag ${labels.tagColor}`}></div>
      <div className="column">
        <div className="title">סוג בקשה</div>
        <div className="content">{labels.type}</div>
      </div>
      <div className="column">
        <div className="title">מספר כרטיס</div>
        <div className="content">{labels.cardNumber}</div>
      </div>
      <div className="column">
        <div className="title">פרופיל</div>
        <div className="content">{labels.profileName}</div>
      </div>
      <div className="column">
        <div className="title">סטטוס</div>
        <div className="content">{labels.hebStatus}</div>
      </div>
      <div className="column">
        <div className="title">פנייה מתאריך</div>
        <div className="content">{getDateFormat(details.createdAt)}</div>
      </div>
    </div>
  );
};

// interface Props {
//   ProfileRequestsList: any;
// }

const CollapseList: FC<{ onRefetch: () => void }> = ({ onRefetch }) => {
  const profilesList = useAppSelector(
    (state) => state.profiles.profilesListReq.data
  );

  return (
    <Collapse
      className="custom-collapse"
      expandIcon={({ isActive }) => {
        return isActive ? (
          <img
            src={`${process.env.PUBLIC_URL}/images/icons-minus.svg`}
            alt="open-info"
          />
        ) : (
          <img
            src={`${process.env.PUBLIC_URL}/images/icons-plus.svg`}
            alt="close-info"
          />
        );
      }}
      expandIconPosition={"left"}
    >
      {profilesList?.map((request, i) => {
        return (
          <Panel
            className={`request-row ${request.status}`}
            header={<RequestHeader details={request} />}
            key={i}
          >
            {request.type === 3 ? (
              <CardRequest profileReq={request} onChange={onRefetch}/>
            ) : (
              <ProfileRequest profileReq={request} />
            )}
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default CollapseList;
