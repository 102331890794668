import FileSaver from 'file-saver';
// import { isIOS } from './device_helper';



const dataType = 'data:application/pdf;base64,';

const generetePdfUrl = (pdfBase64:string)  => `${dataType}${encodeURI(pdfBase64)}`;

const ieFunc = (pdfBase64:string, name:string) => {
    console.log('ieFunc');
  
    const byteCharacters = atob(pdfBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    //@ts-ignore
    window.navigator.msSaveOrOpenBlob(blob, name);
};

async function pdfToBlob(pdfBase64:string, name = '') {
    let blob;
    //@ts-ignore
    if (window.navigator && navigator.msSaveOrOpenBlob) {
        ieFunc(pdfBase64, name);
    } else {
        const resp = await fetch(generetePdfUrl(pdfBase64));
        blob = await resp.blob();
    }

    return blob;
}


export async function openPdfInNewTab(pdfBase64:string, name = '') {
    const pdfBlob = await pdfToBlob(pdfBase64, name).catch(err => {
        console.log(`pdfToBlob error`, err);
        return undefined;
    });

    if (!pdfBlob) {
        return;
    }

    // if (isIOS()) {
    if (false) {
        const reader = new FileReader();
        reader.onload = e => {
            //@ts-ignore
            window.location.href = reader.result;
        
        };
        //@ts-ignore
        reader.readAsDataURL(pdfBlob);
    } else {
        const url = URL.createObjectURL(pdfBlob);
    
        let newWindow = window.open('Esign');
        if (newWindow) {
            newWindow.onload = () => {
                //@ts-ignore
                newWindow.location.href = url;
            };
        }
    }
}

   

//eslint-disable-next-line
const pdfDataType = 'data:application/pdf;base64,';
//eslint-disable-next-line
const xlsxDataType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

function b64toBlobForDownload(b64Data:any, contentType:any, sliceSize:any) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export function downloadFile(excelBase64:string, excelName:string, ending:string) {
    FileSaver.saveAs(b64toBlobForDownload(excelBase64, `${ending}DataType`, ''), `${excelName}.${ending}`);
}


export const openBase64 = (image:string) => {
    let data_image = `${image.split(';')[0]};`;
    let pdfWindow = window.open("");
    pdfWindow?.document.write(`<iframe width='100%' height='100%' src='${data_image}` + image +"'></iframe>")
    // pdfWindow?.document.write("<iframe width='100%' height='100%' src='data:application/pdf;" + image +"'></iframe>")
}
