const base_url = process.env.REACT_APP_API_URL;

const createUrl = (url: string): string => `${base_url}/${url}`;

export const GET_ALL_PROFILES_REQ = createUrl(
  "backoffice/getProfilesReqByStatus/"
);
export const CHENGE_FILE_REQ_PROFILE_STATUS = createUrl(
  "backoffice/changeFileProfileStatus/"
);
export const GET_PROFILES_REQ_COUNT_BY_STATUS = createUrl(
  "backoffice/getProfilesReqCountByStatus/"
);
export const UPDATE_PROFILES_REQ_STATUS = createUrl(
  "backoffice/changeProfileStatusReq/"
);
export const GET_IMAGE_BASE64 = createUrl("images");

export const CARD_PRINT_REQ = createUrl("backoffice/createCard");
