import React, { FC, useState } from "react";
import { Radio } from "antd";
import DynamicBox from "./DynamicBox";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { IProfilesReqCountByStatus, ProfileReqResponse } from "../common/consts/model";
import { useEffect } from "react";
import { FETCH_PROFILES_REQ_COUNT_BY_STATUS } from "../common/services/api";
import { chengeReqCountsByStatus } from "../features/profiles/profilesSlice";

const requestByStatusAmount = (list:ProfileReqResponse[] | null, profilesListReqCounts:IProfilesReqCountByStatus) => {

  // const requestsAmount = {
  //   sumNew: 0,
  //   sumTreatment: 0,
  //   sumReturned: 0,
  //   sumApproved: 0,
  //   sumDenied: 0
  // } 

  // list?.forEach((request: any) => {
  //   switch (request.status) {
  //     case "new-request":
  //       requestsAmount.sumNew++;
  //       break;
  //     case "treatment-request":
  //       requestsAmount.sumTreatment++
  //       break;
  //     case "returned-request":
  //       requestsAmount.sumReturned++
  //       break;
  //     case "approved-request":
  //       requestsAmount.sumApproved++
  //       break;
  //     case "denied-request":
  //       requestsAmount.sumDenied++
  //       break;
  //     default:
  //       break;
  //   }
  // })

  const profileRequestsTabs = [
    {
      imageUrl: `${process.env.PUBLIC_URL}/images/icons-back-office-new-requests.svg`,
      title: profilesListReqCounts.new_request,
      description: "בקשות חדשות",
      color: "orange",
    },
    {
      imageUrl:`${process.env.PUBLIC_URL}/images//icons-back-office-request-in-progress.svg`,
      title: profilesListReqCounts.treatment_request,
      description: "בקשות בטיפול",
      color: "light-blue",
    },
    {
      imageUrl:`${process.env.PUBLIC_URL}/images//icons-back-office-second-time-request.svg`,
      title: profilesListReqCounts.returned_request,
      description: "בקשות חוזרות",
      color: "dark-blue",
    },
    {
      imageUrl:`${process.env.PUBLIC_URL}/images//icons-back-office-request-approved.svg`,
      title: profilesListReqCounts.approved_request,
      description: "בקשות שאושרו",
      color: "light-green",
    },
    {
      imageUrl:`${process.env.PUBLIC_URL}/images//icons-back-office-request-not-approved.svg`,
      title: profilesListReqCounts.denied_request,
      description: "בקשות שנדחו",
      color: "red",
    },
  ];

  return profileRequestsTabs;
}

interface Props {
  onTabChanged: any;
}

const BoxesContainer: FC<Props> = ({ onTabChanged }) => {
  const [selectedTab, setSelectedTab] = useState<string>();
  const profilesList = useAppSelector((state) => state.profiles.profilesListReq.data);
  const profilesListReqCounts = useAppSelector((state) => state.profiles.profilesListReq.reqCountsByStatus);
  const profileRequestsTabs = requestByStatusAmount(profilesList, profilesListReqCounts);
  const dispatch = useAppDispatch()

  useEffect(()=>{
    getReqProfilesCount()
  },[profilesList])

  const getReqProfilesCount = async() =>{
    const data = await FETCH_PROFILES_REQ_COUNT_BY_STATUS();
    dispatch(chengeReqCountsByStatus(data))
  }

  const handleTabChange = (e: any) => {
    console.log("tab changed: ", e.target.value);

    selectedTab === e.target.value && (e.target.value = '') && (e.target.checked = false);

    switch (e.target.value) {
        case 'בקשות חדשות':
            onTabChanged('new-request')
            break;
        case 'בקשות בטיפול':
            onTabChanged('treatment-request')
            break;
        case 'בקשות חוזרות':
            onTabChanged('returned-request')
            break;
        case 'בקשות שאושרו':
            onTabChanged('approved-request')
            break;
        case 'בקשות שנדחו':
            onTabChanged('denied-request')
            break;
    }
    setSelectedTab(e.target.value);
  };
  
  const onClick = (e: any) => {
    // console.log('onClick: ', e.target.value);
    // console.log('radio group value: ', selectedTab);
    if (e.target.value === selectedTab)  {
        onTabChanged('all');  
        e.target.checked = false;
        setSelectedTab('');
    }
  }

  return (
    <Radio.Group
      className="boxes-container"
      value={selectedTab}
      onChange={handleTabChange}
    >
      {profileRequestsTabs.map((tab: any, key: any) => {
        return (
          <Radio.Button key={key} className="tab" value={tab.description} onClick={onClick}>
            <DynamicBox
              imageUrl={tab.imageUrl}
              title={tab.title}
              description={tab.description}
              color={tab.color}
            />
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

export default BoxesContainer;
